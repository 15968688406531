<template>
  <div class="problemListDetails">
    <Breadcrumb></Breadcrumb>
    <!-- <el-button type="primary" style="margin-bottom: 10px;" size="mini" @click="$router.back()">
      返 回
      </el-button> -->
    <detail :list_id="id"></detail>
  </div>
</template>

<script>
import Detail from "@/views/api/teaching/questList/detail";
export default {
  components: {
    Detail
  },
  data () {
    return {
      id: '',
    }
  },
  methods: {
    routeUpdate() {
      let query = this.$route.query
      this.id = query.id
    },
  }
};
</script>